import React, { useState } from "react";
import "../../BookingDetails.css";
import Top_navbar from "../../../DashBaord/Top_navbar";
import { CheckOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { FaRegCircleCheck } from "react-icons/fa6";
import { IoArrowBack } from "react-icons/io5";
import { DatePicker } from "antd";
import BookingFilterDrawer from "./ReScheduleFilter";

import { notification } from "antd";


import moment from "moment";
import { ASSETS_BASE_URL } from "../../../../config/constants";
import { chnageStatus, cancelBooking } from '../../../../controllers/bookings/dayCareController';
import { useLocation, useNavigate, Link } from "react-router-dom";

import BookingConformatuinPopUp from "../BookingConformatuinPopUp";
import BookingDeclineConformationPopup from "../BookingDeclineConformationPopup";
import { calculateAge } from "../../../../controllers/common";
const BookingDetails = () => {
  // re-schedule Drawer
  const [drawerOpen, setDrawerOpen] = useState(false);
  const RequesttoggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const [bookingData, setBookingData] = useState(state);

  const [order_status, setOrderStatus]=useState("");
  const [popupMessage, setPopupMessage]=useState("");
  
  const [popUpOpen, setPopUpOpen] = useState(false);
  const togglePopUp = () => {
    setPopUpOpen(!popUpOpen);
  };

  const [declinePopUpOpen, setdeclinePopUpOpen] = useState(false);
  const toggleDeclinePopUp = () => {
    setdeclinePopUpOpen(!declinePopUpOpen);
  };

  const renderOrderButton = (currentStatus, newStatus, buttonText, popupMessage) => {
    return bookingData.status === currentStatus && (
      <button
        type="button"
        className="order_now_heading_button1"
        onClick={() => {
          setPopupMessage(popupMessage);
          setOrderStatus(newStatus);
          togglePopUp();
        }}
      >
        <FaRegCircleCheck /> {buttonText}
      </button>
    );
  };

  const handleOrderStatusChange = async (status='') =>{  
    try {
      let statusError = false;
      let bookingStatus = bookingData.status;
      if(status === 'Accept' && bookingData.status === 'Pending'){
        bookingStatus = 'Accept'
      } else if(status === 'Completed' && bookingData.status === 'Accept'){
        bookingStatus = 'Completed'
      } else {
        statusError = true
      }
      if(statusError === true){
        notification.open({
          message: `Oops!`,
          description: " Your booking status not changed .",
          placement: "topRight",
          icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
          duration : 2
        });
      } else{
        const options = {
          bookingId : bookingData._id,
          status : bookingStatus
        }
        const res = await chnageStatus(options);
        if(res.status){
          notification.open({
            message: `Booking Status Changed`,
            description: " Your booking status changed.",
            placement: "topRight",
            icon: <CheckOutlined style={{ color: "green" }} />,
            duration : 2
          });
          navigate('/bookings/day-care/list');
        } else{
          notification.open({
            message: `Oops!`,
            description: " Your booking status not changed.",
            placement: "topRight",
            icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
            duration : 2
          });
        }
      }
    } catch (error) {
      notification.open({
        message: `Oops!`,
        description: "Server is under maintenance.",
        placement: "topRight",
        icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
        duration : 2
      });
    }
  };

  const handleOrderDecline = async (reason='') =>{  
    try {
      let statusError = false;
      let bookingStatus = bookingData.status;
      if(bookingData.status !== 'Complete'){
        bookingStatus = 'Canceled'
      } else {
        statusError = true
      }
      if(statusError === true){
        notification.open({
          message: `Oops!`,
          description: " Your booking status not changed.",
          placement: "topRight",
          icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
          duration : 2
        });
      } else{
        const options = {
          bookingId : bookingData._id,
          reason : reason
        }
        const res = await cancelBooking(options);
        if(res.status){
          notification.open({
            message: `Booking Decline!`,
            description: "Booking Decline Successfully.",
            placement: "topRight",
            icon: <CheckOutlined style={{ color: "green" }} />,
            duration : 2
          });
          navigate('/bookings/day-care/list');
        } else{
          notification.open({
            message: `Oops!`,
            description: "Your booking not decline.",
            placement: "topRight",
            icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
            duration : 2
          });
        }
      }
    } catch (error) {
      notification.open({
        message: `Oops!`,
        description: "Server is under maintenance.",
        placement: "topRight",
        icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
        duration : 2
      });
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <div>
          <div className="orders_main_contaniner">
            <div>
              {/* <Top_navbar title={`Booking Details`}></Top_navbar> */}
              <nav class="navbar navbar-light  justify-content-between w-100 navbar_content">
                <Link
                    to="/bookings/day-care/list"
                    class="navbar-brand common_inventory_heading"
                    >
                    <IoArrowBack className="orders_now_IoArrow" /> Day care booking details{" "}
                  </Link>
              </nav>
            </div>
            <div className="order_now_card_tilte">
              <div class="card">
                <div class="card-body order_now_date_and_time_section">
                  <div>
                    <span className="orders_now_billing_details_heading">
                      Booking Id : {bookingData?.booking_seq_id}
                    </span>
                    <address className="order_now_address_date_and_time">
                      <span>
                        {moment(`${bookingData?.createdAt}`).format(
                          "MMM DD, YYYY, hh:mm A"
                        )}
                      </span>{" "}
                      <br />
                      <span>Booking Status : {bookingData.status}</span>
                    </address>
                  </div>
                  <div className="order_now_heading_button">
                    {bookingData.status !== "Completed" &&
                      bookingData.status !== "Canceled" &&
                      bookingData.status !== "Hold" && (
                        <>
                          <span
                            className="order_now_vendor_re_schedule"
                            onClick={RequesttoggleDrawer}
                          >
                            Re-schedule
                          </span>
                          <button
                            type="button"
                            className="order_now_heading_button2 "
                            onClick={() => {
                              toggleDeclinePopUp();
                            }}
                          >
                            <FaRegCircleCheck /> Decline Order
                          </button>
                          {renderOrderButton(
                            "Pending",
                            "Accept",
                            "Accept Booking",
                            "Do you want to accept this booking?"
                          )}
                          {(bookingData?.paymentStatus === 'Pending' || bookingData?.paymentStatus === 'pending')?
                            renderOrderButton(
                              "Accept",
                              "Completed",
                              "Complete Booking",
                              "Do you want to complete this booking and mark as paid?"
                            )
                          :
                            renderOrderButton(
                              "Accept",
                              "Completed",
                              "Complete Booking",
                              "Do you want to complete this booking?"
                            )
                          }
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8">
                {(bookingData.status === "Canceled" ||
                  bookingData.status === "Hold") && (
                  <>
                    <div className="card mb-4">
                      <div className="card-body">
                        <span className="orders_now_billing_details_heading">
                          Decline Details
                        </span>
                        <address className="order_now_address">
                          <span className="text-danger">
                            Status : {bookingData.status}
                          </span>
                          <span className="text-danger">
                            Reason : {bookingData?.reason}
                          </span>
                          <span className="text-danger">
                            Date & Time :{" "}
                            {moment(`${bookingData?.canceled_date}`).format(
                              "MMM DD, YYYY, hh:mm A"
                            )}
                          </span>
                          <span className="text-danger">
                            By : {bookingData?.canceled_user_type}
                          </span>
                        </address>
                      </div>
                    </div>
                  </>
                )}
                {bookingData?.bookingdetails.length > 0 &&
                  bookingData?.bookingdetails.map((item) => (
                    <>
                      <div className="card mb-4 ">
                        <div className="card-body">
                          <div class="mb-3 d-flex justify-content-between">
                            <div>
                              <span className="booking_details_billing_details_heading">
                                Services
                              </span>
                            </div>
                          </div>
                          <table class="table table-borderless booking_deatils_table_for_tr orders_now_table">
                            <tbody>
                              
                              <tr>
                                <td colspan="2" className="orders_now_subtotal">
                                  Service
                                </td>
                                <td class="text-end order_now_rupees">
                                  {item?.services?.name}{" "}
                                </td>
                              </tr>
                              {bookingData?.pick_drop === 'Y' && (
                                <tr>
                                  <td colspan="2" className="orders_now_subtotal">
                                    Service Type
                                  </td>
                                  <td class="text-end order_now_rupees">
                                    {" "}
                                    Pick & Drop
                                  </td>
                                </tr>
                              )}
                              <tr>
                                <td colspan="2" className="orders_now_subtotal">
                                  Amount
                                </td>
                                <td class="text-end order_now_rupees">
                                  AED {item?.services?.price.toFixed(2)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  ))}
                <div className="card mb-4 ">
                  <div className="card-body">
                    <div class="mb-3 d-flex justify-content-between">
                      <div>
                        <span className="booking_details_billing_details_heading">
                          Billing Details
                        </span>
                      </div>
                    </div>
                    <table class="table table-borderless booking_deatils_table_for_tr orders_now_table">
                      <tbody>
                        <tr>
                          <td colspan="2" className="orders_now_subtotal">
                            Sub-Total
                          </td>
                          <td class="text-end order_now_rupees">
                            AED{" "}
                            {(bookingData?.total -
                              (parseFloat(bookingData?.subTotal) * 5) /
                                100).toFixed(2)}{" "}
                          </td>
                        </tr>
                        {bookingData?.discount !== 0 && (
                          <>
                            <tr>
                              <td colspan="2" className="orders_now_subtotal">
                                {" "}
                                Discount{" "}
                              </td>
                              <td class="text-end order_now_rupees">
                                - AED {bookingData?.discount?.toFixed(2)}{" "}
                              </td>
                            </tr>
                          </>
                        )}
                        {bookingData?.isTreatsUsed === 'Y' && (
                          <>
                            <tr>
                              <td colspan="2" className="orders_now_subtotal">
                                {" "}
                                Treats ({bookingData?.treats?.toFixed(2)}){" "}
                              </td>
                              <td class="text-end order_now_rupees">
                                - AED {bookingData?.treats_discount?.toFixed(2)}{" "}
                              </td>
                            </tr>
                          </>
                        )}
                        {bookingData?.pick_drop_charge > 0 && (
                          <>
                            <tr>
                              <td colspan="2" className="orders_now_subtotal">
                                Pick & Drop Charges
                              </td>
                              <td class="text-end order_now_rupees">
                                {" "}
                                AED {bookingData?.pick_drop_charge?.toFixed(2)}{" "}
                              </td>
                            </tr>
                          </>
                        )}
                        <tr>
                          <td colspan="2" className="orders_now_subtotal">
                            Tax{" "}
                            <span className="booking_details_convenience">
                              ( 5% VAT )
                            </span>
                          </td>
                          <td class="text-end order_now_rupees">
                            {" "}
                            AED {((parseFloat(bookingData?.subTotal) * 5) / 100).toFixed(2)}
                          </td>
                        </tr>
                        {/* <hr /> */}
                        <tr class="fw-bold">
                          <td
                            colspan="2"
                            className="booking_details_payable_amount"
                          >
                            Payable Amount
                          </td>
                          <td class="text-end booking_details_payable_amount">
                            AED {bookingData?.paidAmount?.toFixed(2)}{" "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    {/* ... (rest of the code) */}
                  </div>
                </div>

                {bookingData?.petInfo && (
                  <>
                    <div className="card mb-4">
                      <div className="card-body">
                        <div class="mb-3 d-flex justify-content-between">
                          <div>
                            <span className="booking_details_billing_details_heading">
                              Pet's Details
                            </span>
                          </div>
                        </div>
                        <table class="table table-borderless booking_deatils_table_for_tr orders_now_table">
                          <tbody>
                            <tr>
                              <td colspan="2" className="orders_now_subtotal">
                                Name 
                              </td>
                              <td class="text-end order_now_rupees">
                                {bookingData?.petInfo?.name?.charAt(0).toUpperCase() + bookingData?.petInfo?.name?.slice(1).toLowerCase()}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2" className="orders_now_subtotal">
                                Gender 
                              </td>
                              <td class="text-end order_now_rupees">
                              {bookingData?.petInfo?.gender}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2" className="orders_now_subtotal">
                              Age 
                              </td>
                              <td class="text-end order_now_rupees">
                                <span dangerouslySetInnerHTML={{__html: calculateAge(bookingData?.petInfo?.age)}}></span>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2" className="orders_now_subtotal">
                                Breed 
                              </td>
                              <td class="text-end order_now_rupees">
                                {bookingData?.petInfo?.breed}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}

                {bookingData?.pick_drop === 'Y' && (
                  <>
                    <div className="card mb-4">
                      <div className="card-body">
                        <div class="mb-3 d-flex justify-content-between">
                          <div>
                            <span className="booking_details_billing_details_heading">
                              Pick & Drop Details
                            </span>
                          </div>
                        </div>
                        <table class="table table-borderless booking_deatils_table_for_tr orders_now_table">
                          <tbody>
                            <tr>
                              <td colspan="2" className="orders_now_subtotal">
                                Type 
                              </td>
                              <td class="text-end order_now_rupees">
                                {bookingData?.pick_drop_type}
                              </td>
                            </tr>
                            {bookingData?.pick_drop_type === 'One-way'?(
                              <>
                              <tr>
                                <td colspan="2" className="orders_now_subtotal">
                                  Location 
                                </td>
                                <td class="text-end order_now_rupees">
                                {bookingData?.from_address?.charAt(0).toUpperCase() + bookingData?.from_address?.slice(1).toLowerCase()}
                                </td>
                              </tr>
                              <tr>
                              <td colspan="2" className="orders_now_subtotal">
                                Center 
                              </td>
                              <td class="text-end order_now_rupees">
                                {bookingData?.shopId?.shop_name}
                              </td>
                              </tr>
                              <tr>
                                <td colspan="2" className="orders_now_subtotal">
                                  Pickup Time 
                                </td>
                                <td class="text-end order_now_rupees">
                                  {bookingData?.oneWayPickUpLocationTime}
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2" className="orders_now_subtotal">
                                  Notes 
                                </td>
                                <td class="text-end order_now_rupees">
                                  {bookingData?.pickup_notes}
                                </td>
                              </tr>
                              </>
                            ):(
                              <>
                              <tr>
                                <td colspan="2" className="orders_now_subtotal">
                                  Location 
                                </td>
                                <td class="text-end order_now_rupees">
                                  {bookingData?.from_address?.charAt(0).toUpperCase() + bookingData?.from_address?.slice(1).toLowerCase()}
                                </td>
                              </tr>
                              <tr>
                              <td colspan="2" className="orders_now_subtotal">
                                Center 
                              </td>
                              <td class="text-end order_now_rupees">
                                {bookingData?.shopId?.shop_name}
                              </td>
                              </tr>
                              <tr>
                                <td colspan="2" className="orders_now_subtotal">
                                Pickup location time 
                                </td>
                                <td class="text-end order_now_rupees">
                                  {bookingData?.twoWayPickUpLocationTime}
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2" className="orders_now_subtotal">
                                Pickup center time  
                                </td>
                                <td class="text-end order_now_rupees">
                                  {bookingData?.twoWayPickUpCenterTime}
                                </td>
                              </tr>
                              <tr>
                                <td colspan="2" className="orders_now_subtotal">
                                  Notes 
                                </td>
                                <td class="text-end order_now_rupees">
                                  {bookingData?.pickup_notes}
                                </td>
                              </tr>
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}
                {bookingData?.notes && (
                  <div className="card mb-4">
                    <div className="card-body">
                      <span className="orders_now_billing_details_heading">
                        Notes
                      </span>
                      <address className="order_now_address">
                        <span>{bookingData?.notes}</span>
                      </address>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-lg-4">
                <div className="card mb-4">
                  <div className="card-body">
                    <span className="orders_now_billing_details_heading">
                      User Details
                    </span>
                    <address className="order_now_address">
                      <span>Name: {bookingData?.ownerInfo?.name}</span>
                      <span>
                        Phone No. : +971 - {bookingData?.ownerInfo?.phone}
                      </span>
                    </address>
                  </div>
                </div>

                <div className="card mb-4">
                  <div className="card-body">
                    <span className="orders_now_billing_details_heading">
                      Appointment Date & Time
                    </span>
                    <table className="table table-borderless orders_now_billing_details_bottom orders_now_table">
                      <tbody>
                        <tr>
                          <td colspan="2" className="orders_now_subtotal">
                            Date
                          </td>
                          <td class="text-end order_now_rupees">
                            {moment(bookingData?.appointment_date).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2" className="orders_now_subtotal">
                            Time
                          </td>
                          <td className="text-end order_now_rupees">
                            {bookingData?.appointment_time}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div className="card mb-4">
                  <div className="card-body">
                    <span className="orders_now_billing_details_heading">
                      Payment Details
                    </span>
                    <address className="booking_deatils_order_now_address">
                      <span>Payment Mode : {bookingData?.paidBy?bookingData.paidBy?.charAt(0).toUpperCase() + bookingData.paidBy?.slice(1).toLowerCase():"Cash"}</span>
                      <span>Payment Status : {bookingData?.paymentStatus?.charAt(0).toUpperCase() + bookingData?.paymentStatus?.slice(1).toLowerCase()}</span>
                    </address>
                  </div>
                </div>

                <div className="card mb-4">
                  <div className="card-body">
                    <span className="orders_now_billing_details_heading">
                      Store Details
                    </span>
                    <table className="table table-borderless orders_now_billing_details_bottom orders_now_table">
                      <tbody>
                        <tr>
                          <td class="order_now_rupees">
                            {`Store Name : ${bookingData?.shopId?.shop_name}`}
                            <br />
                            {`Phone : ${bookingData?.shopId?.phone}`}
                            <br />
                            {`Email : ${bookingData?.shopId?.email}`}
                            <br />
                            {`Street : ${bookingData?.shopId?.street}`}
                            <br />
                            {`Address : ${bookingData?.shopId?.address}`}
                            <br />
                            {`City : ${bookingData?.shopId?.city}`}
                            <br />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                
              </div>

              {/* ////// */}
            </div>
          </div>
        </div>
      </div>
      <BookingFilterDrawer
        drawerOpen={drawerOpen}
        RequesttoggleDrawer={RequesttoggleDrawer}
        bookingData={bookingData}
      />
      <BookingConformatuinPopUp 
        popUpOpen={popUpOpen} 
        togglePopUp={togglePopUp}
        status={order_status}
        handleOrderChnageStatus={handleOrderStatusChange}
        message = {popupMessage}
      />
      <BookingDeclineConformationPopup 
        popUpOpen={declinePopUpOpen} 
        togglePopUp={toggleDeclinePopUp}
        handleOrderDecline={handleOrderDecline}
      />
    </div>
  );
}

export default BookingDetails;
