import React, { useState } from "react";
import { useNavigate } from "react-router-dom/dist";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { IoMdClose } from "react-icons/io";
import { Flex, Spin } from "antd";

import { logout } from "../../controllers/accounts/Account"


const LogoutConformationPopup = ({ popUpOpen, togglePopUp }) => {
  const navigate = useNavigate();
  const handleCancel = () => {
    togglePopUp();
  };
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    setIsLoading(true);
    const res = await logout();
    if(res.status === true){
      setIsLoading(false);
      navigate('/');
    } else{
      setIsLoading(false);
      sessionStorage.clear();
      localStorage.clear();
      navigate('/');
    }
    
  };

  return (
    <Dialog
      open={popUpOpen}
      onClose={togglePopUp}
      maxWidth="md"
      PaperProps={{
        className: "myDialogPopUp",
      }}
    >
      <div className="myDrawer">
        <div className="myMainDrawerClass">
          <div>
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="myFilterClass"
            >
              Confirm
            </Typography>
          </div>
          <div>
            <IconButton onClick={togglePopUp}>
              <IoMdClose />
            </IconButton>
          </div>
        </div>
        <Typography
          variant="body1"
          component="div"
          className="my_delete_popup_paragraph"
        >
          <p>Do you want to logout?</p>
        </Typography>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            No
          </Button>
          <Button onClick={handleDelete} style={{ color: "red" }}>
            Yes
          </Button>
        </DialogActions>
      </div>
      <Flex align="center" gap="middle">
        <Spin 
          size="large" 
          spinning={isLoading}
          fullscreen={true}
        />
      </Flex>
    </Dialog>
  );
};

export default LogoutConformationPopup;
