import  { API_STORE_URL }  from '../../config/constants';
import {getRequest, postRequest} from '../API';
/*********************************************************
 *  This function is use to login api
 *********************************************************/
export const login = async (options)=>{
    try{
        const {email, password}= options;
        if(!email){
            return {status : false, message:"Email is required"}
        } else if(!password){
            return {status : false, message:"Password is required"}
        } else{
            const posrData ={ 
                url : API_STORE_URL+'users/login',
                postData : options 
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                return {status : true, message:`Your One Time Password is sent to ${email}`}
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
        }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function

/*********************************************************
 *  This function is use to login api
 *********************************************************/
export const verifyLoginOtp = async (options)=>{
    try{
        const {email, otp}= options;
        if(!email){
            return {status : false, message:"Email is required"}
        } else if(!otp){
            return {status : false, message:"One Time Password is required"}
        } else{
            const posrData ={ 
                url : API_STORE_URL+'users/verify-login-otp',
                postData : options
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                sessionStorage.setItem('TOKEN', res.data.response.result.token);
                sessionStorage.setItem('STORE-INFO', JSON.stringify(res.data.response.result));
                return {status : true, message:`Welcome Back! ${res.data.response.result.admin_first_name}`};
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
        }
    }catch(error){
        console.log(error);
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function


/*********************************************************
 *  This function is use to logout user and clear session and local storage
 *********************************************************/
export const logout = async() => {
    try{
        const posrData ={ 
            url : API_STORE_URL+'users/logout',
         }
        await getRequest(posrData);
        sessionStorage.clear();
        localStorage.clear();
        return {status : true, message:"Success"}
    } catch(error){
        sessionStorage.clear();
        localStorage.clear();
        return {status : true, message:"Success"}
    }   
}

/*********************************************************
 *  This function is use to forgot password
 *********************************************************/
export const forgotPassword = async (options)=>{
    try{
        const {email}= options;
        if(!email){
            return {status : false, message:"Email is required"}
        } else{
            const posrData ={ 
                url : API_STORE_URL+'users/forgot-password',
                postData : options
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                return {status : true, message:`Your One Time Password is sent to ${email}`}
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
        }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function

/*********************************************************
 *  This function is use to forgot password
 *********************************************************/
export const resetPassword = async (options)=>{
    try{
        const {email, otp, password}= options;
        if(!email){
            return {status : false, message:"Email is required"}
        } else if(!password){
            return {status : false, message:"New Password required"}
        } else if(!otp){
            return {status : false, message:"OTP is required"}
        } else{
            const posrData ={ 
                url : API_STORE_URL+'users/reset-password',
                postData : options
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                return {status : true, message:`Password reset successfully.`}
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
        }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function

/*********************************************************
* Function Name : signUp
* Description   : Sent signup request 
* By            : Afsar Ali
* Date          : 25-04-2024 
*********************************************************/
export const signUp = async (options) =>{
    try {
        const params = {
            url : `${API_STORE_URL}users/signup`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        console.log(error)
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function


/*********************************************************
* Function Name : updateProfile
* Description   : this function is used for updateProfile
* By            : Noor Alam
* Date          : 16May-2024 
*********************************************************/
export const updateProfile = async (options) =>{
    try {
        const params = {
            url : `${API_STORE_URL}users/update-profile`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            sessionStorage.setItem('STORE-INFO', JSON.stringify(res?.data?.response?.result))
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : getDashboardData
* Description   : this function is used for get dashboard data
* By            : Afsar Ali
* Date          : 05-June-2024 
*********************************************************/
export const getDashboardData = async (options) =>{
    try {
        const params = {
            url : `${API_STORE_URL}users/dashboard`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function

/*********************************************************
* Function Name : updateStoredData
* Description   : this function is used for get updateStoredData
* By            : Noor Alam
* Date          : 18-June-2024 
*********************************************************/
export const updateStoredData = async (options) =>{
    try {
        const params = {
            url : `${API_STORE_URL}users/update-store`,
            postData : options
        }
        const res = await postRequest(params);
        if(res.status === true || res.status === 200){
            sessionStorage.setItem('STORE-INFO', JSON.stringify(res?.data?.response?.result))
            return {status : true, result : res?.data?.response?.result};
        } else{
            return {status : false, message:res?.response?.data?.statusMessage}
        }
    } catch (error) {
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
};//End of Function



