import React, { useState } from "react";
import { IoSettingsOutline, IoNotificationsOffSharp } from "react-icons/io5";
import { MdOutlinePayment } from "react-icons/md";
import { IoMdStarOutline } from "react-icons/io";
import { BiSupport } from "react-icons/bi";
import { CiDiscount1 } from "react-icons/ci";
import { NavLink } from "react-router-dom";
import { TbCategory } from "react-icons/tb";
import { MdOutlineInventory2 } from "react-icons/md";
import { IoHomeOutline } from "react-icons/io5";
import { RiBarChartHorizontalLine } from "react-icons/ri";
import { CiMail } from "react-icons/ci";
// import SidebarLogo from "../Image/SideBarIcon.png";
import SidebarLogo from "../../Image/SideBarIcon.png";
import { CiShoppingBasket } from "react-icons/ci";
import { FaGreaterThan } from "react-icons/fa";
import { TbBrandBooking } from "react-icons/tb";
import { IoDocumentTextSharp } from "react-icons/io5";
import { IoAnalyticsSharp } from "react-icons/io5";
import { CiCircleList } from "react-icons/ci";
import "./SideBar.css";
import { VscAccount } from "react-icons/vsc";
import { TbPointFilled } from "react-icons/tb";
import { Collapse } from "antd";
import { ASSETS_BASE_URL } from "../../config/constants";
const { Panel } = Collapse;

const SidebarItem = ({ to, icon, name, isOpen, className }) => (
  <NavLink to={to} className={`link ${className}`} activeClassName="active">
    <div className="icon">{icon}</div>
    <div style={{ display: isOpen ? "block" : "none" }} className="link_text">
      {name}
    </div>
  </NavLink>
);

const Sidebar = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  const handleCollapseToggle = () => {
    setIsOpen(!isOpen);
  };

  const user = JSON.parse(sessionStorage.getItem('STORE-INFO'));
  const [USERDATA, setUserData] = useState(user || "");

  return (
    <div
      className={`container-fluid side_bar_main_div ${isOpen ? "sidebar-open" : "sidebar-closed"
        }`}
    >
      <div
        style={{ width: isOpen ? "300px" : "70px", position: "relative " }}
        className="sidebar"
      >
        <div className="top_section">
          <img
            src={SidebarLogo}
            className="sidebar_logo"
            alt="Logo"
            style={{
              display: isOpen ? "block" : "none",
              height: "50px",
              width: "auto",
            }}
          />
          <div
            style={{ marginLeft: isOpen ? "130px" : "0px" }}
            className="bars"
          >
            <RiBarChartHorizontalLine onClick={toggle} />
          </div>
        </div>
        <div className="my_sidebar_all_section">
          <SidebarItem
            to="/dashboard"
            icon={<IoHomeOutline />}
            name="Dashboard"
            isOpen={isOpen}
          />



          <SidebarItem
            to="/orders/list"
            icon={<CiShoppingBasket />}
            name="Orders"
            isOpen={isOpen}
          />


          <div>
            <Collapse accordion className="my_collapse_icon">
              <Panel
                header={
                  <div>
                    <TbBrandBooking className="sidebar_collapse_iohomeoutline" />
                    <span
                      className={`sidebar_collapse_iohomeoutline_categoires ${isOpen ? "visible " : "hidden "
                        }`}
                    >
                      Bookings
                    </span>
                  </div>
                }
                key="categories"
                className={`side_bar_categories ${isOpen ? "arrow-visible active " : "arrow-hidden "
                  }`}
              >
                <SidebarItem to="/bookings/grooming/list" icon={<TbPointFilled />} name="Grooming" isOpen={isOpen} ></SidebarItem>
                <SidebarItem to="/bookings/veterinary/list" icon={<TbPointFilled />} name="Veterinary" isOpen={isOpen} ></SidebarItem>
                <SidebarItem to="/bookings/training/list" icon={<TbPointFilled />} name="Training" isOpen={isOpen} ></SidebarItem>
                <SidebarItem to="/bookings/boarding/list" icon={<TbPointFilled />} name="Boarding" isOpen={isOpen} ></SidebarItem>
                <SidebarItem to="/bookings/day-care/list" icon={<TbPointFilled />} name="Day Care" isOpen={isOpen} ></SidebarItem>
              </Panel>
            </Collapse>
          </div>

          {/* <SidebarItem
            to="/support"
            icon={<BiSupport />}
            name="Support"
            isOpen={isOpen}
          /> */}
          <SidebarItem
            to="/notifications"
            icon={<IoNotificationsOffSharp />}
            name="Notifications"
            isOpen={isOpen}
            className={`custom_profile_class_productList`}
          />


          <SidebarItem
            icon={
              <img
                src={user.image ? ASSETS_BASE_URL + user.image : "https://frisbee-llc.s3.me-central-1.amazonaws.com/images/favicon.png"}
                alt=""
                className="user_profile_pic_sidebar"
                onClick={handleCollapseToggle}
                width="100px"
              />
            }
            to="/profile"
            name={
              <div className="sidebar_profile_main_content_section">
                <div>
                  <div className="sidebar_profile_main_content">
                    <div className="user_profile_pic_Admin_name">
                      <span className="user_profile_pic_Admin_panel">
                        {`${user?.shop_name}`}
                      </span>{" "}
                      <br />
                      <span className="user_profile_pic_Admin_panel_">
                        Store
                      </span>
                    </div>
                  </div>
                </div>
                <div>
                  <FaGreaterThan
                    className="side_bar_fagreaterthan"
                    onClick={handleCollapseToggle}
                  />
                </div>
              </div>
            }
            isOpen={isOpen}
            className="custom_profile_class"
          >
          </SidebarItem>
        </div>
      </div>
      <main>{children}</main>
    </div>
  );
};

export default Sidebar;
