import React from "react";
import { Flex, Spin } from "antd";

const Loading = ({ isLoading = false }) => {
  return (
    <Flex align="center" gap="middle">
        <Spin 
            size="large" 
            spinning={isLoading}
            fullscreen={true}
        />
    </Flex>
  );
};

export default Loading;

