import "../DashBaord/main_dashboard.css";
import { CiSearch } from "react-icons/ci";
import { LuBellDot } from "react-icons/lu";
import React, { useState, useEffect } from "react";
const ProfileTopNavBar = () => {
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <div className={isSticky ? "sticky" : ""}>
        <div className="container_fluid">
          <div className="row">
            <div className="col-md-7">
              <h1 className="main_heading">My Profile</h1>
            </div>
            <div className="col-md-4">
              <div className="search_box">
                <input
                  type="text"
                  placeholder="Search.."
                  className="search_input"
                ></input>
                <CiSearch id="search_icon" />
              </div>
            </div>
            <div className="col-md-1">
              <button className="btn_icon">
                <LuBellDot />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProfileTopNavBar;
