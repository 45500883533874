import React from 'react';

const TransactionList = ({ transactions = [] }) => (
  <ul>
    {transactions.length > 0 ? (
      transactions.slice(0, 4).map((transaction) => (
        <li key={transaction.id}>
          <div className="tran_details">
            {/* <img src={transaction.image} alt={transaction.userData} /> */}
            <div className="tran_infor">
              <p className="trans_name">{transaction?.userData?.name}</p>
              <p className="trans_id">Txn. ID- {transaction?.txnNumber}</p>
            </div>
          </div>
          <div>
            <p className="price">{`AED ${transaction?.paidAmount}`}</p>
          </div>
        </li>
      ))
    ) : (
      <p>No transactions available</p>
    )}
  </ul>
);
export default TransactionList;
