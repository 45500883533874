import React, {useState} from "react";
import { Select } from "antd";
import ReactApexChart from "react-apexcharts";

function Recent_order({ graphData }) {

  const [ showGraph, setShowGraph ] = useState('shop');
  const getLastSixMonths = () => {
    const months = [];
    const currentDate = new Date();

    for (let i = 0; i < 6; i++) {
        const month = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1);
        const monthName = month.toLocaleString('default', { month: 'short' });
        const year = month.getFullYear();
        months.push(`${monthName} ${year}`);
    }

    return months.reverse();
  };

  const handleGraphChange = (value, option) => {
    try {
      setShowGraph(value);
    } catch (error) {
      console.log('error', error)
    }
  };

  // const series = [
  //   {
  //     name: "No. of Orders",
  //     data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
  //   },
  // ];
  const series = [
    {
      name: "Sales",
      // data: [50, 49, 60, 70, 91, 125],
      data: graphData[showGraph],
    },
  ];
  // const options = {
  //   chart: {
  //     height: 350,
  //     type: "area",
  //   },
  //   xaxis: {
  //     categories: [
  //       "2016",
  //       "2017",
  //       "2018",
  //       "2019",
  //       "2020",
  //       "2021",
  //       "2022",
  //       "2023",
  //       "2024",
  //     ],
  //   },
  // };
    const options = {
      chart: {
        height: 350,
        type: "area",
      },
      xaxis: {
        categories: getLastSixMonths(),
      },
    };
  return (
    <>
      <div className="inventory_tabs_main_div mt-0">
        <div class="card ">
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="card-title mb-0">Financial Analytics</h5>
              <div class="card-title mb-0">
                <Select
                  defaultValue="Filters"
                  className="select_filter_class"
                  onChange={handleGraphChange}
                  style={{
                    width: 120,
                  }}
                  bordered={false}
                  options={[
                    {
                      value: "shop",
                      label: "Shop",
                    },
                    {
                      value: "grooming",
                      label: "Grooming",
                    },
                    {
                      value: "boarding",
                      label: "Boarding",
                    },
                    {
                      value: "veterinary",
                      label: "Veterinary",
                    },
                    {
                      value: "training",
                      label: "Training",
                    },
                    {
                      value: "daycare",
                      label: "Day Care",
                    }
                  ]}
                />
              </div>
            </div>
          </div>
          <div class="card-body">
            <div>
              <ReactApexChart
                options={options}
                series={series}
                type="area"
                height={350}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Recent_order;
